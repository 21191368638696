export const DataHandle = {
	methods: {
		//切换检索按钮 样式
		getSearchTextColor(obj, index) {
			this.isActive = false;
			obj.forEach(item => {
				item['className'] = ""
			})
			obj[index]['className'] = "searchTextColor";
			/* if(index != "-1"){
				this.isActive = false;
				obj.forEach(item => {
					item['className'] = ""
				})
				obj[index]['className'] = "searchTextColor";
			} else {
				obj.forEach(item => {
					item['className'] = "searchTextColorDefault"
				})
			} */
		},
	}
}
