<template>
  <div class="monitoring-report">
		<el-tabs v-model="tabIndex" @tab-click="handleClick">
			<el-tab-pane label="" name="0">
				<span slot="label"><i class="iconfont icon-shangchuan"></i> 上传文件 </span>
			</el-tab-pane>
			<el-tab-pane label="" name="1">
				<span slot="label"><i class="iconfont icon-jieshouwengao"></i> 接收文件 </span>
			</el-tab-pane>
		</el-tabs>
		<div class="seacrh-div" style="margin-top: 10px;padding: 10px 20px;">
    	<el-form label-width="" :model="params">
    	  <el-form-item label="">
					<!-- <el-input v-model="params.keywords" placeholder="请输入名称">
						<el-button slot="append" icon="el-icon-search" style="font-size:16px" @click.native="handleSearch()">搜索</el-button>
					</el-input> -->
					<el-autocomplete class="inline-input" v-model="params.keywords" :fetch-suggestions="querySearch" placeholder="请输入文件名称" :trigger-on-focus="false" @select="handleSelect">
						<el-button slot="append" icon="el-icon-search" style="font-size:16px" @click.native="handleSearch()">搜索</el-button>
					</el-autocomplete>
    	  </el-form-item>
    	  <el-form-item label-width="75px" label="文件类型:">
    	    <span class="searchTextColorDefault" v-for="(item,index) in reportTypeList" :key="item.name"  @click="handleSearchCheck('reportType',index)" :class="item.className">
    	      {{item.name}}
    	    </span>
    	  </el-form-item>
    		<el-form-item label-width="75px" label="文件时间:">
    		  <span class="searchTextColorDefault" v-for="(item,index) in timeScopeList" :key="item.name" @click="handleSearchCheck('timeScope',index)" :class="item.className">
    		    {{item.name}}
    		  </span>
					<el-date-picker
					  v-model="params.startTime"
						value-format="yyyy-MM-dd"
					  type="date"
						clearable
						@change="dateChangeStart"
						style="width: 150px;margin-left: 20px;"
					  placeholder="开始时间">
					</el-date-picker>
					至
					<el-date-picker
					  v-model="params.endTime"
						value-format="yyyy-MM-dd"
					  type="date"
						clearable
						@change="dateChangeEnd"
						style="width: 150px;"
					  placeholder="结束时间">
					</el-date-picker>
    		</el-form-item>
				<el-form-item label-width="75px" label="已下发:" v-show="sessionStorageLevel=='1'&&tabIndex=='0'">
					<span class="searchTextColorDefault" v-for="(item,index) in isSendList" :key="item.name"  @click="handleSearchCheck('sendType',index)" :class="item.className">
					  {{item.name}}
					</span>
				</el-form-item>
				<el-form-item label-width="75px" :label="citySearchName" v-show="sessionStorageLevel == '1'">
					<span class="searchTextColorDefault" v-for="(item,index) in departIdList" :key="item.name"  @click="handleSearchCheck('departType',index)" :class="item.className">
					  {{item.name}}
					</span>
				</el-form-item>
    	</el-form>
    </div>
		<div class="content-box">
			<div class="caozuo-box">
				<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
				<el-button size="mini" type="primary" @click.native="handleAdd()" style="background-color: #007CFF;" v-show="tabIndex == '0'">上传</el-button>
				<el-button size="mini" type="success" @click.native="handleDown()" style="background-color: #30C190;">下载</el-button>
				<el-button size="mini" type="danger" @click.native="handleDelete()">删除</el-button>
			</div>
			<div class="report-situation" v-loading="loadingFlag">
				<el-checkbox-group v-model="checkedId" @change="handleCheckedCitiesChange">
					<ul>
						<li v-for="(item,index) in dataList" :key="index" style="width: 15%;margin: 10px 2.5%;">
							<span v-show="sessionStorageLevel == '1'">
								<el-button size="mini" type="primary" @click.native="handleIssce(item,index)" v-show="tabIndex == '0'" style="position: absolute;bottom: -7px;right: 17px;padding: 2px 5px;">下发</el-button>
							</span>
							<el-checkbox :label="item.id">上传人员：{{item.userName}}</el-checkbox>
							<h5>{{item.reportName}}</h5>
							<div>
								<p>
									<span v-if="item.type == 1">月报</span>
									<span v-if="item.type == 2">年报</span>
									<span v-if="item.type == 3">企业监测报告</span>
									<span v-if="item.type == 4">其他</span>
								</p>
								<p>{{item.reportTime}}</p>
							</div>
						</li>
					</ul>
					<img v-show="loadingFlag==false&&dataList.length==0" :src="`${baseUrl}img/bt-nodata-now.png`" style="display: block;margin: 100px auto;" />
				</el-checkbox-group>
			</div>
			<el-pagination @current-change="handleCurrentChange" :page-size="params.size" background :current-page.sync="params.current" layout="prev, pager, next, jumper" :total="dataTotal"></el-pagination>
		</div>
		<el-dialog title="新增报告" :visible.sync="dialogVisibleMore" width="25%" class="exportModalBox" :close-on-click-modal="false">
			<el-form ref="form" :model="formUpload" label-width="70px">
			  <el-form-item label="报告名称">
			    <el-input v-model="formUpload.name"></el-input>
			  </el-form-item>
			  <el-form-item label="报告类型">
			    <el-select v-model="formUpload.type" placeholder="">
			      <el-option label="月报" :value="1"></el-option>
			      <el-option label="年报" :value="2"></el-option>
			      <el-option label="企业监测报告" :value="3"></el-option>
			      <el-option label="其他" :value="4"></el-option>
			    </el-select>
			  </el-form-item>
			</el-form>
			<div class="borderBox">
				<el-link type="primary" @click="uploadALL"><span class="el-icon-upload"></span> 选择文件</el-link>
				<span style="font-size: 12px;color: #999;margin-left: 30px;">仅支持导入 pdf 、doc 、docx  、xls  、xlsx 格式文件</span>
				<input type="file" style="display:none" ref="uploadRef" accept=".pdf,.doc,.docx,.xls,.xlsx" @change="changeFlieData($event)" />
			</div>
		</el-dialog>
		
		<el-dialog title="下发文件" :visible.sync="dialogVisibleIssce" width="40%" class="exportModalBox" :close-on-click-modal="false">
			<div style="text-align: left;">
				<div style="font-size: 12px;color: #6da9e7;text-indent: 24px;">{{recentlyIssce}}</div>
				<div style="margin: 30px 0;"></div>
				<el-checkbox :indeterminate="isIndeterminateIssce" v-model="checkAllIssce" @change="handleCheckAllChangeIssce">全选</el-checkbox>
				<div style="margin: 15px 0;"></div>
				<el-row>
					<el-col :span="6" v-for="(item,index) in citiesIssce" :key="index">
						<el-checkbox-group v-model="checkedCitiesIssce" @change="handleCheckedCitiesChangeIssce">
							<el-checkbox :label="item.value">{{item.name}}</el-checkbox>
						</el-checkbox-group>
					</el-col>
				</el-row>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="handleSubmitIssce">确 定</el-button>
			</span>
		</el-dialog>
  </div>
</template>

<script>
import {exportPdf,exportZip,exportMethodWord} from '../provider/index.js' //导出Excel公用方法
import {DataHandle} from "@/mixins/dataPro.js" //公共方法
export default {
	name:"monitoring-report",
  mixins:[DataHandle],
	data() {
		return {
			sessionStorageLevel:JSON.parse(sessionStorage.getItem("user_xjfj")).level == "1",
			baseUrl:process.env.BASE_URL,
			baseModelUrl:this.$pulick.urlStr,
			tabIndex:'0',
			citySearchName:'下发地市:',
			params: {
				current: 1,
				size: 10,
				keywords: '',
				reportType: "",
				timeScope:0,
				startTime:'',
				endTime:'',
				departId: '',
				isSend:''
			},
			reportTypeList:[
				{name: "全部",value: "",className: "searchTextColor"},
				{name: "月报",value: 1,className: ""},
				{name: "年报",value: 2,className: ""},
				{name: "企业监测报告",value: 3,className: ""},
				{name: "其他",value: 4,className: ""},
			],
			isSendList:[
				{name: "全部",value: "",className: "searchTextColor"},
				{name: "是",value: 1,className: ""},
				{name: "否",value: 0,className: ""},
			],
			departIdList:[
				{name: "全部",value: "",className: "searchTextColor"},
				{name: "月报",value: 1,className: ""},
				{name: "年报",value: 2,className: ""},
				{name: "企业监测报告",value: 3,className: ""},
				{name: "其他",value: 4,className: ""},
			],
			timeScopeList:[
				{name: "全部",value: 0,className: "searchTextColor"},
				{name: "一周以内",value: 1,className: ""},
				{name: "一月以内",value: 2,className: ""},
				{name: "三月以内",value: 3,className: ""},
				{name: "半年以内",value: 4,className: ""},
				{name: "一年以内",value: 5,className: ""},
			],
			loadingFlag: false,
			dataList:[],
			dataTotal:0,
			checkedId:[],
			checkAll: false,
			isIndeterminate: true,
			dialogVisibleMore:false,//批量新增  弹框
			formUpload:{
				name:'',
				type:1,
			},
			dialogVisibleIssce:false,//批量下发  弹框
			recentlyIssce:'',
			idIssce:'',
			checkAllIssce: false,
			checkedCitiesIssce: [],
			citiesIssce: [],
			isIndeterminateIssce: true
		}
	},
	created() {
		this.getDepartList();//加载部门
		this.getDataList();//获取列表
	},
	methods: {
		handleClick(tab, event) {
			this.tabIndex = tab.index;
			if(tab.index == 0){
				this.citySearchName = "下发地市:";
			} else if(tab.index == 1){
				this.citySearchName = "报送地市:";
			}
			this.params.keywords = '';
			this.params.reportType = this.reportTypeList[0].value;
			this.getSearchTextColor(this.reportTypeList, 0)
			this.params.startTime = '';
			this.params.endTime = '';
			this.params.timeScope = this.timeScopeList[0].value;
			this.getSearchTextColor(this.timeScopeList, 0)
			this.params.departId = this.departIdList[0].value;
			this.getSearchTextColor(this.departIdList, 0)
			this.params.isSend = this.isSendList[0].value;
			this.getSearchTextColor(this.isSendList, 0)
			this.currentPage = 1; //初始第一页
			this.getDataList(); //加载列表
		},
		//模糊提示 功能
		querySearch(queryString, cb) {
			this.$provider.get('/wg-ifrRisk/clueReport/page', {
				params: {
					sendStatus:Number(this.tabIndex),
					name:this.params.keywords,
					reportType:"",
					timeScope:0,
					startTime:"",
					endTime:"",
					isSend:"",
					departId:"",
					pageNo:1,
					pageSize:50,
				}
			}).then(res => {
				let restaurants = [];
				res.data.records.filter((item, index) => {
					restaurants.push({
						value: item.reportName,
						id: item.id
					})
				})
				cb(restaurants); // 调用 callback 返回建议列表的数据
				/* let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;//确定是否为首字
				cb(results); // 调用 callback 返回建议列表的数据*/
			})
		},
		createFilter(queryString) {
			return (restaurant) => {
				return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
			};
		},
		handleSelect(item) {
			this.params.keywords = item.value; //选中提示   填入
			this.params.current = 1;//初始第一页
			this.getDataList();//加载列表
		},
		// 搜索  按钮  点击   加载
		handleSearch() {
			this.params.current = 1;
			this.getDataList();//加载列表
		},
		//其他检索   切换 
		handleSearchCheck(type, index) {
			if (type == "reportType") {
				this.params.reportType = this.reportTypeList[index].value;
				this.getSearchTextColor(this.reportTypeList, index)
			} else if (type == "timeScope") {
				this.params.startTime = '';
				this.params.endTime = '';
				this.params.timeScope = this.timeScopeList[index].value;
				this.getSearchTextColor(this.timeScopeList, index)
			} else if(type == 'departType'){
				this.params.departId = this.departIdList[index].value;
				this.getSearchTextColor(this.departIdList, index)
			} else if(type == 'sendType'){
				this.params.isSend = this.isSendList[index].value;
				this.getSearchTextColor(this.isSendList, index)
			}
			this.params.current = 1;
			this.getDataList()
		},
		//日期  范围  检索  
		dateChangeStart(val){
			if(val != null){
				this.params.timeScope = "";
				if(this.compareDate(this.params.startTime,this.params.endTime)){
					this.params.startTime = this.params.endTime;
					this.params.endTime = val;
				}
				//this.getSearchTextColor(this.timeScopeList, "");
			} else {
				this.params.startTime = "";
				if(this.params.endTime != null){
					this.params.timeScope = "";
				} else {
					this.params.timeScope = 0;
					this.getSearchTextColor(this.timeScopeList, 0)
				}
			}
			this.params.current = 1;
			this.getDataList();
		},
		dateChangeEnd(val){
			if(val != null){
				this.params.timeScope = "";
				if(this.compareDate(this.params.startTime,this.params.endTime)){
					this.params.endTime = this.params.startTime;
					this.params.startTime = val;
				}
				//this.getSearchTextColor(this.timeScopeList, "");
			} else {
				this.params.endTime = "";
				if(this.params.startTime != null){
					this.params.timeScope = "";
				} else {
					this.params.timeScope = 0;
					this.getSearchTextColor(this.timeScopeList, 0);
				}
			}
			this.params.current = 1;
			this.getDataList();
		},
		compareDate (d1, d2) {
		  let reg = new RegExp('-', 'g')
		  return ((new Date(d1.replace(reg, '/'))) > (new Date(d2.replace(reg, '/'))))
		},
		//获取列表
		getDataList(){
			this.loadingFlag = true; //loading 显示
			this.$provider.get("/wg-ifrRisk/clueReport/page", {
				params:{
					sendStatus:Number(this.tabIndex),
					name:this.params.keywords,
					reportType:this.params.reportType,
					timeScope:this.params.timeScope,
					startTime:this.params.startTime,
					endTime:this.params.endTime,
					isSend:this.params.isSend,
					departId:this.sessionStorageLevel=="1" ? this.params.departId : '',
					pageNo:this.params.current,
					pageSize:this.params.size,
				}
			}).then(res => {
				this.loadingFlag = false; 
				this.dataList = res.data.records;
				this.dataTotal = res.data.total;
			})
		},
		handleCurrentChange(val) {
		  this.params.current = val
		  this.getDataList();
		},
		handleCheckAllChange(val) {
			let dataList = this.dataList;
			let idCheckList = [];
			dataList.filter((item,index) => {
				idCheckList.push(item.id)
			})
			this.checkedId = val ? idCheckList : [];
			this.isIndeterminate = false;
		},
		handleCheckedCitiesChange(value) {
			let checkedCount = value.length;
			this.checkAll = checkedCount === this.dataList.length;
			this.isIndeterminate = checkedCount > 0 && checkedCount < this.dataList.length;
		},
		
		//加载部门  省    检索
		getDepartList() {
			this.$provider.get('/wg-system/depart/list', {
				params: {
					parentCode: 660000
				}
			}).then(res => { 
				this.departIdList = [{name: "全部",value: "",className: "searchTextColor"}];
				for(let i=0;i<res.data.length;i++){
					this.departIdList.push({
						name: res.data[i].name,
						value: res.data[i].id,
						className: ""
					})
					this.citiesIssce.push({
						name: res.data[i].name,
						value: res.data[i].id,
					})
				}
			})
		},
		
		//下发  
		handleIssce(item,index){
			this.dialogVisibleIssce = true;
			this.idIssce = item.id;
			this.checkedCitiesIssce = [];
			this.$provider.get("/wg-ifrRisk/clueReport/recently", {
				params:{
					id:item.id
				}
			}).then(res => {
				this.recentlyIssce = res.data;
			})
		},
		//下发   确定
		handleSubmitIssce(){
			this.$provider.get("/wg-ifrRisk/clueReport/ext", {
				params:{
					ids:this.idIssce,
					departIds:this.checkedCitiesIssce.length!=0 ? this.checkedCitiesIssce.join(",") : ""
				}
			}).then(res => {
				if(res.code == 200){
					this.$message({
						message: "文件下发成功",
						type: 'success'
					});
					this.dialogVisibleIssce = false;
					this.params.current = 1;
					this.getDataList();
				}
			})
		},
		//下发   全选
		handleCheckAllChangeIssce(val) {
			let dataList = this.citiesIssce;
			let idCheckList = [];
			dataList.filter((item,index) => {
				idCheckList.push(item.value)
			})
			this.checkedCitiesIssce = val ? idCheckList : [];
			this.isIndeterminateIssce = false;
		},
		handleCheckedCitiesChangeIssce(value) {
			let checkedCount = value.length;
			this.checkAllIssce = checkedCount === this.citiesIssce.length;
			this.isIndeterminateIssce = checkedCount > 0 && checkedCount < this.citiesIssce.length;
		},
		// 下载  批量
		handleDown(){
			if(this.checkedId.length != 0){
				//window.location.href = this.baseModelUrl+"/wg-ifrRisk/clueReport/download/"+this.checkedId.join(",");
				let myObj = {
					method: 'get',
					url: this.baseModelUrl+"/wg-ifrRisk/clueReport/download/"+this.checkedId.join(","),
					fileName: '企业监测报告',
					params: ''
				}
				if(this.checkedId.length > 1){
					exportZip(myObj, this); // 导出zip公用方法
				} else {
					exportMethodWord(myObj, this); // 导出word公用方法
				}
			} else {
				this.$message({
					message: "请先勾选",
					type: 'warning'
				});
			}
		},
		// 删除  批量
		handleDelete(){
			if(this.checkedId.length != 0){
				this.$provider.get("/wg-ifrRisk/clueReport/delete", {
					params:{
						ids:this.checkedId.join(",")
					}
				}).then(res => {
					this.$message({
						message: res.msg,
						type: 'success'
					});
					this.params.current = 1;
					this.getDataList();
				}) 
			} else {
				this.$message({
					message: "请先勾选",
					type: 'warning'
				});
			}
		},
		//上传
		handleAdd() {
			this.dialogVisibleMore = true;
			this.formUpload.name = "";
			this.formUpload.type = 1;
		},
		uploadALL() {
			//this.$refs.uploadRef.click();
			let fileInput = this.$refs["uploadRef"];
			if (fileInput) {
				fileInput.value = "";
				fileInput.click();
			}
		},
		//文件选择
		changeFlieData(e) { 
			if(this.formUpload.name == ""){
				this.$message({
					message: "报告名称不能为空，请填写",
					type: 'warning'
				});
			} else {
				let formData = new FormData();
				Array.from(e.target.files).forEach(file => {
					formData.append("file", file);
					formData.append("reportName", this.formUpload.name);
					formData.append("reportType", this.formUpload.type);
				});
				this.$provider.post('/wg-ifrRisk/clueReport/upload', formData).then(res => {
					if(res.code == 200){
						this.$message({
							message: "报告上传成功",
							type: 'success'
						});
						this.dialogVisibleMore = false; //关闭弹框
						this.params.current = 1;
						this.getDataList();
					}
				})
			}
		}, 
	},
}
</script>

<style scoped lang="stylus"></style>
